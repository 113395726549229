@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
               -webkit-appearance: none;
                margin: 0;
        }
 
        input[type=number] {
            -moz-appearance: textfield;
        }

		.circulo {
			width: 7px;
			height: 7px;
			margin-right: 7px;
			background-color: #383A5C; 
			border-radius: 100%;
			display: inline-block;
		  }
		  .active { 
			background-color: #e534fc;
		  }


.PhoneInputInput{
  background-color: transparent;
  padding: 0.5rem 0.2rem;
}